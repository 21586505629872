import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Heading from '../components/Heading';
import BookList from '../components/BookList';
import Loader from '../components/Loader';
import '../css/Books.css';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';

const BooksPage = () => {
  const [booksData, setBooksData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // fetch page
    fetch('https://autor.inesstrohmaier.com/api/site?id=5')
      .then((response) => response.json())
      .then((data) => {
        setPageData(data);
      })
      .catch((error) => console.log(error));
    // fetch books
    fetch('https://autor.inesstrohmaier.com/api/books')
      .then((response) => response.json())
      .then((data) => {
        setBooksData(data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (booksData.length > 0 && pageData.title) {
      setLoading(false);
    }
  }, [booksData, pageData]);

  return (
    <div className="grey-body">
      <Loader loading={loading} />
      <NavBar />
      <Heading title={pageData?.title} description={pageData?.subtitle} />
      <div className="section">
        <div className="w-layout-grid headline-grid">
          <div id="w-node-ef7261df-8462-f5ef-5504-5680af4d03bb-56830c13" className="headline-wrap-centered">
            <div className="text-label">{pageData.title_small}</div>
            <div className="size3-text size3-bottom-clear">{pageData.subtitle_small}</div>
          </div>
        </div>
      </div>
      <div className="wrapper m80-100">
        <BookList books={booksData} size="lg" />
      </div>

      <Footer />
    </div>
  );
};
export default BooksPage;
