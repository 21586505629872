import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Heading from '../components/Heading';
import Loader from '../components/Loader';
import '../css/About.css';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // fetch page
    fetch('https://autor.inesstrohmaier.com/api/site?id=9')
      .then((response) => response.json())
      .then((data) => {
        setPageData(data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (pageData.title) {
      setLoading(false);
    }
  }, [pageData]);

  return (
    <div>
      <Loader loading={loading} />
      <NavBar />
      <Heading title={pageData?.title} description={pageData?.subtitle} />
      <div className="wrapper m0-50">
        <div className="section">
          <div className="w-layout-grid headline-grid">
            <div id="w-node-_420088eb-45f2-550d-7632-fa89bf0e2db3-2a830c0e">
              <div className="text-label">{pageData.title_small}</div>
              <div className="size3-text size3-bottom-clear">{pageData.subtitle_small}</div>
              <p className="paragraph-70" dangerouslySetInnerHTML={{ __html: pageData?.text_vita }}></p>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper m0-100">
        <div className="section">
          <div className="w-layout-grid content23-grid">
            <div id="w-node-_6d4ade57-b937-f780-b80c-0c79b1da0990-2a830c0e" className="content23-image"></div>
            <div id="w-node-_6d4ade57-b937-f780-b80c-0c79b1da0991-2a830c0e" className="content23-item-wrap">
              <div className="content23-item">
                <div className="size5-text">Presse</div>
                <div className="paragraph-70" dangerouslySetInnerHTML={{ __html: pageData?.text_press }}></div>
              </div>
            </div>
            <div id="w-node-_6d4ade57-b937-f780-b80c-0c79b1da09a0-2a830c0e" className="content23-item-wrap">
              <div className="content23-item">
                <div className="size5-text">Auszeichnungen</div>
                <div className="paragraph-70" dangerouslySetInnerHTML={{ __html: pageData?.text_awards }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AboutPage;
