import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavBar = () => {
  const location = useLocation();

  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div data-collapse="medium" data-animation="default" data-duration={400} data-no-scroll={1} data-easing="ease" data-easing2="ease" role="banner" className="navbar1 active-nav1 w-nav">
        <div className="nav1-wrap">
          <HashLink to="/#top" className="nav-link w-nav-link w--current " id="w-nav-brand" aria-current="page">
            <img src="./logo.png" height={48} alt="Ines Strohmaier Logo" className="logo" style={{ objectFit: 'contain' }} />
          </HashLink>

          <div className="nav1-menu-wrap">
            <nav role="navigation" className="nav1-menu w-nav-menu">
              <HashLink to="/#top" className={'nav-link w-nav-link' + (location.pathname === '/' ? ' w--current' : '')}>
                Home
              </HashLink>
              <HashLink to="/about#top" className={'nav-link w-nav-link' + (location.pathname === '/about' ? ' w--current' : '')}>
                Vita
              </HashLink>
              <HashLink to="/events#top" className={'nav-link w-nav-link' + (location.pathname === '/events' ? ' w--current' : '')}>
                Termine
              </HashLink>
              <HashLink to="/stage#top" className={'nav-link w-nav-link' + (location.pathname === '/stage' ? ' w--current' : '')}>
                Bühne
              </HashLink>
              <HashLink to="/books#top" className={'nav-link w-nav-link' + (location.pathname === '/books' ? ' w--current' : '')}>
                Bücher
              </HashLink>
              <HashLink to="/education#top" className={'nav-link w-nav-link' + (location.pathname === '/education' ? ' w--current' : '')}>
                Bildung
              </HashLink>
              <HashLink to="/contact#top" className={'nav-link w-nav-link' + (location.pathname === '/contact' ? ' w--current' : '')}>
                Kontakt
              </HashLink>
            </nav>
            <div className="menu-button w-nav-button" onClick={() => setExpanded(!expanded)}>
              <img src="https://assets.website-files.com/5d9d08afa8a650dd43830c03/5d9d08afa8a650d83b830c83_Menu%20Icon.png" width={20} alt="" className="menu-icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="navbar-1" style={{ display: expanded ? 'block' : 'none', height: '100vh', position: 'fixed', zIndex: 9999, backgroundColor: '#fff', width: '100%', top: 0, bottom: 0 }}>
        <div className="navbar1">
          <div className="nav1-wrap">
            <HashLink to="/#top" className="nav-link w-nav-link w--current " id="w-nav-brand" aria-current="page">
              <img src="./logo.png" height={48} alt="Ines Strohmaier Logo" className="logo" style={{ objectFit: 'contain' }} />
            </HashLink>

            <div style={{ justifySelf: 'flex-end', padding: '18px' }}>
              <div className="menu-button" syle={{}} onClick={() => setExpanded(!expanded)}>
                <img src="https://assets.website-files.com/5d9d08afa8a650dd43830c03/5d9d08afa8a650d83b830c83_Menu%20Icon.png" width={20} alt="" className="menu-icon" />
              </div>
            </div>
          </div>
        </div>
        <nav role="navigation" style={{ display: 'flex', flexDirection: 'column', textTransform: 'uppercase' }}>
          <HashLink to="/#top" className={'nav-link w-nav-link' + (location.pathname === '/' ? ' w--current' : '')} style={{ padding: '14px 24px' }}>
            Home
          </HashLink>
          <HashLink to="/about#top" className={'nav-link w-nav-link' + (location.pathname === '/about' ? ' w--current' : '')} style={{ padding: '14px 24px' }}>
            Vita
          </HashLink>
          <HashLink to="/events#top" className={'nav-link w-nav-link' + (location.pathname === '/events' ? ' w--current' : '')} style={{ padding: '14px 24px' }}>
            Termine
          </HashLink>
          <HashLink to="/stage#top" className={'nav-link w-nav-link' + (location.pathname === '/stage' ? ' w--current' : '')} style={{ padding: '14px 24px' }}>
            Bühne
          </HashLink>
          <HashLink to="/books#top" className={'nav-link w-nav-link' + (location.pathname === '/books' ? ' w--current' : '')} style={{ padding: '14px 24px' }}>
            Bücher
          </HashLink>
          <HashLink to="/education#top" className={'nav-link w-nav-link' + (location.pathname === '/education' ? ' w--current' : '')} style={{ padding: '14px 24px' }}>
            Bildung
          </HashLink>
          <HashLink to="/contact#top" className={'nav-link w-nav-link' + (location.pathname === '/contact' ? ' w--current' : '')} style={{ padding: '14px 24px' }}>
            Kontakt
          </HashLink>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
