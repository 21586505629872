import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  const location = useLocation();

  return (
    <div className="wrapper m0-50" style={{ backgroundColor: '#ffffff' }}>
      <div className="footer2">
        <div className="section">
          <div className="w-layout-grid footer2-form-grid">
            <div id="w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28b9d-f4c28b99" className="size3-text size3-full-clear">
              Kontaktieren Sie mich gerne!
            </div>
            <div id="w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28b9f-f4c28b99">
              <a className="button-small w-100 form-centered-button w-button" href="mailto:office@inesstrohmaier.com">
                mail schreiben
              </a>
            </div>
          </div>
          <div className="content">
            <div className="footer2-divider" />
          </div>
          <div className="w-layout-grid footer2-links-grid">
            <div id="w-node-_603e5635-65ae-52d0-2ca4-514031499c33-f4c28b99" style={{ fontWeight: 800, fontSize: 'larger', textTransform: 'uppercase' }}>
              <HashLink to="/#top" className="nav-link w-nav-link w--current " id="w-nav-brand" aria-current="page">
                <img src="./logo.png" height={64} alt="Ines Strohmaier Logo" className="logo" style={{ objectFit: 'contain' }} />
              </HashLink>
            </div>
            <div id="w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28baf-f4c28b99" className="footer2-links-wrap">
              <HashLink to="/#top" className={'footer2-link' + (location.pathname === '/' ? ' w--current' : '')}>
                Home
              </HashLink>
              <HashLink to="/about#top" className={'footer2-link' + (location.pathname === '/about' ? ' w--current' : '')}>
                Vita
              </HashLink>
              <HashLink to="/events#top" className={'footer2-link' + (location.pathname === '/events' ? ' w--current' : '')}>
                Termine
              </HashLink>
              <HashLink to="/stage#top" className={'footer2-link' + (location.pathname === '/stage' ? ' w--current' : '')}>
                Bühne
              </HashLink>
              <HashLink to="/books#top" className={'footer2-link' + (location.pathname === '/books' ? ' w--current' : '')}>
                Bücher
              </HashLink>
              <HashLink to="/education#top" className={'footer2-link' + (location.pathname === '/education' ? ' w--current' : '')}>
                Bildung
              </HashLink>
              <HashLink to="/contact#top" className={'footer2-link' + (location.pathname === '/contact' ? ' w--current' : '')}>
                Kontakt
              </HashLink>
            </div>
            <div id="w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bba-f4c28b99" className="footer2-links-wrap">
              <a target="_blank" href="https://www.facebook.com/ines.strohmaier/" className="footer2-link">
                Facebook
              </a>
              <a target="_blank" href="https://www.instagram.com/ines.strohmaier" className="footer2-link">
                Instagram
              </a>
              <a target="_blank" href="https://www.instagram.com/ines.strohmaier" className="footer2-link">
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="w-layout-grid footer2-bottom-grid">
          <div id="w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bc5-f4c28b99" className="footer2-bottom-left-column">
            <a href="https://lume.work/" target="_blank" className="footer2-bottom-link">
              Website by lume 2022
            </a>
          </div>
          <div id="w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bca-f4c28b99">Alle Rechte vorbehalten &copy; Ines Strohmaier</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
