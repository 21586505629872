import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

const BookItem = ({ book, size = 'sm' }) => {
  const [image, setImage] = useState('https://autor.inesstrohmaier.com/assets/img/books/' + book.id + '.jpg?v=' + book.image_version);

  return size === 'lg' ? (
    <div key={book.id} id={book.id} className="styleguide-section-wrap book-item-large">
      <p className="label black-label styleguide-section-label">{book.field_type}</p>
      <div className="book-item-large-container">
        <div style={{ flex: 2 }}>
          <img src={image} alt={book.name + ' Cover'} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </div>
        <div style={{ flex: 4 }}>
          <h1>{book.name}</h1>
          <div className="paragraph-70" dangerouslySetInnerHTML={{ __html: book.description }}></div>
          {book?.field_link_buy?.uri && (
            <div className="book-item-buy-button-wrapper">
              <a data-w-id="0472793a-0f99-bde7-3ab1-ef5fe0f585e7" href={book?.url_buy} target="_blank" className="button-small w-inline-block">
                <div>Buch kaufen</div>
                <img
                  src="https://assets.website-files.com/5d9d08afa8a650dd43830c03/5d9d08afa8a6507b67830c3a_Arrow%402x.svg"
                  alt=""
                  className="button-arrow"
                  style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div key={book.id} className="w-dyn-item">
      <div className="image-wrap">
        <HashLink to={'/books/#' + book.id} className="item-overlay w-inline-block book-item-link">
          <div className="button-small white-small">
            <div>Buch ansehen</div>
          </div>
        </HashLink>
        <div style={{ backgroundImage: `url(${image})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} className="product-image product-image-size-m">
          {book.featured == 1 && <div className="label inside-label">Aktuell</div>}
        </div>
      </div>
      <div className="product3-content-wrap">
        <div className="size6-text">{book.category}</div>
        <div className="size4-text">{book.name}</div>
      </div>
    </div>
  );
};

export default BookItem;
