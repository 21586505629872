import React from 'react';
import EducationItem from './EducationItem';

const EducationList = ({ educations, size = 'sm' }) => {
  return (
    <div className="section">
      <div role="list" className="w-layout-grid grid">
        {educations.map((education) => (
          <EducationItem key={education.id} education={education} size={size} />
        ))}
      </div>
    </div>
  );
};

export default EducationList;
