import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Heading from '../components/Heading';
import Loader from '../components/Loader';
import '../css/About.css';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';

const ContactPage = () => {
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // fetch page
    fetch('https://autor.inesstrohmaier.com/api/seite/7e2e1384-0270-4cf4-929a-45ce812c1695')
      .then((response) => response.json())
      .then((data) => {
        console.log('data', data);
        setPageData(data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (pageData.title) {
      setLoading(false);
    }
  }, [pageData]);

  return (
    <div>
      <Loader loading={loading} />
      <NavBar />
      <Heading title={pageData?.title} description={pageData?.subtitle} />
      <div className="wrapper m0-50">
        <div className="section">
          <div className="w-layout-grid headline-grid">
            <div id="w-node-_420088eb-45f2-550d-7632-fa89bf0e2db3-2a830c0e">
              <div className="text-label">Kontakt</div>
              <div className="size1-text">Ines Strohmaier</div>
              <p className="paragraph-70">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod minima, suscipit ratione corrupti aperiam molestias! Consectetur, quisquam eligendi, molestias minus voluptas quo, itaque laudantium rerum aspernatur eveniet dicta atque in?</p>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper m0-100">
        <div className="section">
          <div className="w-layout-grid content23-grid">
            <div id="w-node-_6d4ade57-b937-f780-b80c-0c79b1da0990-2a830c0e" className="content23-image"></div>
            <div id="w-node-_6d4ade57-b937-f780-b80c-0c79b1da0991-2a830c0e" className="content23-item-wrap">
              <div className="content23-item">
                <div className="text-label">Presse</div>
                <div className="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis blanditiis rem reiciendis iusto error iste accusantium consequuntur consequatur. Harum dicta illum modi molestiae eligendi accusantium facere nisi consectetur, ut esse?</div>
              </div>
            </div>
            <div id="w-node-_6d4ade57-b937-f780-b80c-0c79b1da09a0-2a830c0e" className="content23-item-wrap">
              <div className="content23-item">
                <div className="text-label">Auszeichnungen</div>
                <div className="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eveniet voluptatum quae cum error, maiores nemo quibusdam possimus maxime eius numquam iste excepturi debitis hic aliquid adipisci est facere eligendi.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ContactPage;
