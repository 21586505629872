import React from 'react';
import { Link } from 'react-router-dom';

const HeadingHome = ({ title, description }) => {
  return (
    <div className="wrapper m0-100">
      <div className="w-dyn-list" style={{ backgroundColor: '#ffffff' }}>
        <div role="list" className="w-dyn-items">
          <div role="listitem" className="w-dyn-item">
            <div className="hero-with-background-wrap hero2-wrap">
              <div className="w-layout-grid hero2-grid">
                <div id="w-node-_1c8e674e-3cef-b4ac-3e58-e51f03a48609-56830c13" className="hero2-content" style={{ height: 'calc(100vh - 100px)' }}>
                  <div className="hero2-title">
                    <div className="size1-text">{title}</div>
                    <p className="paragraph-70">{description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadingHome;
