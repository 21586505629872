import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Heading from '../components/Heading';
import BookList from '../components/BookList';
import StageList from '../components/StageList';
import '../css/Home.css';
import Loader from '../components/Loader';
import { HashLink } from 'react-router-hash-link';
import EducationList from '../components/EducationList';

const HomePage = () => {
  const [booksData, setBooksData] = useState([]);
  const [stagesData, setStagesData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [pageContactData, setPageContactData] = useState([]);
  const [educationsData, setEducationsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pageContactImage, setPageContactImage] = useState('https://autor.inesstrohmaier.com/assets/img/sites/7.jpg?v=12');

  useEffect(() => {
    // fetch page
    fetch('https://autor.inesstrohmaier.com/api/site?id=1')
      .then((response) => response.json())
      .then((data) => {
        setPageData(data);
      })
      .catch((error) => console.log(error));
    // fetch page contact
    fetch('https://autor.inesstrohmaier.com/api/site?id=7')
      .then((response) => response.json())
      .then((data) => {
        setPageContactData(data);
        setPageContactImage('https://autor.inesstrohmaier.com/assets/img/sites/7.jpg?v=12' + data.image_version);
      })
      .catch((error) => console.log(error));
    // fetch books
    fetch('https://autor.inesstrohmaier.com/api/books')
      .then((response) => response.json())
      .then((data) => {
        setBooksData(data);
      })
      .catch((error) => console.log(error));
    // fetch stages
    fetch('https://autor.inesstrohmaier.com/api/stages')
      .then((response) => response.json())
      .then((data) => {
        setStagesData(data);
      })
      .catch((error) => console.log(error));
    // fetch educations
    fetch('https://autor.inesstrohmaier.com/api/educations')
      .then((response) => response.json())
      .then((data) => {
        setEducationsData(data);
      })
      .catch((error) => console.log(error));
  }, []);

  // set loading
  useEffect(() => {
    if (booksData.length > 0 && stagesData.length > 0 && pageData) {
      setLoading(false);
    }
  }, [booksData, pageData]);

  return (
    <div>
      <Loader loading={loading} />
      <NavBar />
      <Heading title={pageData?.title} description={pageData?.subtitle} />
      <div className="wrapper m0-100">
        <div className="section">
          <div className="w-layout-grid content7-grid">
            <div id="w-node-b9822256-bcec-35dd-9e7b-33e4c0cdd70a-56830c13" className="content7-content-wrap">
              <div>
                <div className="size1-text">{pageContactData?.title}</div>
                <div className="paragraph-70">{pageContactData?.subtitle}</div>
                <br></br>
                <HashLink data-w-id="b25b777e-97cb-7f36-0061-514a82f1e76d" to="/about#top" className="button-small w-inline-block">
                  <div className="button-label">Mehr erfahren</div>
                  <img src="https://assets.website-files.com/5d9d08afa8a650dd43830c03/5d9d08afa8a6507b67830c3a_Arrow%402x.svg" alt="" className="button-arrow" />
                </HashLink>
              </div>
            </div>
            <div id="w-node-b9822256-bcec-35dd-9e7b-33e4c0cdd710-56830c13" className="content7-image" style={{ backgroundImage: `url(${pageContactImage})`, backgroundPosition: 'top' }} />
          </div>
        </div>
      </div>
      <div className="section" style={{ marginBottom: '80px' }}>
        <div className="w-layout-grid headline-grid">
          <div id="w-node-ef7261df-8462-f5ef-5504-5680af4d03bb-56830c13" className="headline-wrap-centered">
            <div className="text-label">Bühne</div>
            <div className="size3-text size3-bottom-clear">Bühnen-Glücksmomente</div>
          </div>
        </div>
      </div>
      <div className="wrapper m0-100">
        <StageList stages={stagesData} />
      </div>
      <div className="section" style={{ marginBottom: '100px' }}>
        <div className="content">
          <HashLink data-w-id="b25b777e-97cb-7f36-0061-514a82f1e76d" to="/stage#top" className="button-small w-inline-block">
            <div className="button-label">Mehr zur Bühne erfahren</div>
            <img src="https://assets.website-files.com/5d9d08afa8a650dd43830c03/5d9d08afa8a6507b67830c3a_Arrow%402x.svg" alt="" className="button-arrow" />
          </HashLink>
        </div>
      </div>
      <div className="section" style={{ marginBottom: '80px' }}>
        <div className="w-layout-grid headline-grid">
          <div id="w-node-ef7261df-8462-f5ef-5504-5680af4d03bb-56830c13" className="headline-wrap-centered">
            <div className="text-label">Bildung</div>
            <div className="size3-text size3-bottom-clear">Individuelle Weiterbildungen</div>
          </div>
        </div>
      </div>
      <div className="wrapper m0-100">
        <EducationList educations={educationsData} />
      </div>
      <div className="section">
        <div className="w-layout-grid headline-grid">
          <div id="w-node-ef7261df-8462-f5ef-5504-5680af4d03bb-56830c13" className="headline-wrap-centered">
            <div className="text-label">Bücher</div>
            <div className="size3-text size3-bottom-clear">Schau Dir meine aktuellen Bücher an</div>
          </div>
        </div>
      </div>
      <div className="wrapper m80-50">
        <BookList books={booksData} />
      </div>
      <div className="wrapper m0-100">
        <div className="section">
          <div className="content">
            <HashLink data-w-id="b25b777e-97cb-7f36-0061-514a82f1e76d" to="/books#top" className="button-small w-inline-block">
              <div className="button-label">Alle Bücher ansehen</div>
              <img src="https://assets.website-files.com/5d9d08afa8a650dd43830c03/5d9d08afa8a6507b67830c3a_Arrow%402x.svg" alt="" className="button-arrow" />
            </HashLink>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default HomePage;
