import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './css/Main.css';

import { HashRouter, Routes, Route, Link, useRouteMatch, useParams } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import BooksPage from './pages/BooksPage';
import EventsPage from './pages/EventsPage';
import StagesPage from './pages/StagesPage';
import EducationsPage from './pages/EducationsPage';
import ContactPage from './pages/ContactPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter basename="/">
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/books" element={<BooksPage />} />
      <Route path="/events" element={<EventsPage />} />
      <Route path="/stage" element={<StagesPage />} />
      <Route path="/education" element={<EducationsPage />} />
      <Route path="/contact" element={<ContactPage />} />
    </Routes>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
