import React from 'react';
import StageItem from './StageItem';

const StageList = ({ stages, size = 'sm' }) => {
  // sort stages by order
  const sortedStages = stages.sort((a, b) => a.ordering - b.ordering);

  return (
    <div className="section">
      <div role="list" className="w-layout-grid grid">
        {sortedStages && sortedStages.map((stage) => <StageItem key={stage.id} stage={stage} size={size} />)}
      </div>
    </div>
  );
};

export default StageList;

