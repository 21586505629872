import React from 'react';

const EventItem = ({ event }) => {
  // convert event.date to european format
  const date = new Date(event.date);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
  const dateString = date.toLocaleString('de-DE', options);

  return (
    <div key={event.id} id="w-node-_06ca21c4-6e4d-40dc-13fb-a11da3b94e37-d4830c24" className="styleguide-section-wrap event-item">
      <p className="label black-label styleguide-section-label">{event.category}</p>
      <h5>{dateString}</h5>
      <h1>{event.name}</h1>
      <div className="paragraph-70" dangerouslySetInnerHTML={{ __html: event?.description }}></div>
    </div>
  );
};

export default EventItem;
