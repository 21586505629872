import React from 'react';
import EventItem from './EventItem';

const EventList = ({ events, past = false }) => {
  // sort events by date
  const sortedEvents = events.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  // remove events that are in the past
  const futureEvents = sortedEvents.filter((event) => {
    return new Date(event.date) > new Date();
  });

  // sort events by date desc
  const pastEvents = sortedEvents
    .filter((event) => {
      return new Date(event.date) < new Date();
    })
    .sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

  return (
    <div className="section">
      {past ? (
        <div role="list" className="w-layout-grid grid">
          {pastEvents && pastEvents.map((event) => <EventItem key={event.id} event={event} />)}
        </div>
      ) : (
        <div role="list" className="w-layout-grid grid">
          {futureEvents && futureEvents.map((event) => <EventItem key={event.id} event={event} />)}
        </div>
      )}
    </div>
  );
};

export default EventList;
