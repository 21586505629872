import React, { useEffect, useState } from 'react';
import Typewriter from 'typewriter-effect';

const Loader = ({ loading }) => {
  const [loadingText, setLoadingText] = useState(['Geschichten werden geschrieben...', 'Poesie wird gelebt...', 'Stifte werden gespitzt...', 'Bühnen werden betreten...', 'Bücher werden aufgeschlagen...', 'Gedichte werden gedichtet...']);
  const [randomIndex, setRandomIndex] = useState(Math.floor(Math.random() * loadingText.length));
  const [hideClass, setHideClass] = useState('loader');

  // useEffect(() => {
  //   console.log('check start hiding loader');
  //   if (!loading) {
  //     setTimeout(() => {
  //       console.log('hide loader');
  //       document.getElementById('loader').style.display = 'none';
  //     }, 1000);
  //   }
  // }, [loading, hideClass]);

  // return loader with styles and display flex if loading is true
  return (
    <div id="loader" className={hideClass}>
      <Typewriter
        onInit={(typewriter) => {
          typewriter
            .changeDelay(33)
            .typeString(loadingText[randomIndex])
            .callFunction(() => {
              setHideClass('loader hidden');
              setTimeout(() => {
                console.log('hide loader');
                document.getElementById('loader').style.display = 'none';
              }, 1000);
            })
            .start();
        }}
      />
    </div>
  );
};

export default Loader;
