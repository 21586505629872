import React from 'react';
import BookItem from './BookItem';

const BookList = ({ books, size = 'sm' }) => {
  return (
    <div className="section">
      <div className="w-dyn-list">
        <div role="list" className="product3-grid w-dyn-items" id="books-list">
          {books.map((book) => (
            <BookItem key={book.id} book={book} size={size} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BookList;
