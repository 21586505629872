import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

const EducationItem = ({ education, size = 'sm' }) => {
  const [image, setImage] = useState('https://autor.inesstrohmaier.com/assets/img/educations/' + education.id + '.jpg?v=' + education.image_version);

  return size === 'lg' ? (
    <div key={education.id} id={education.id} className="styleguide-section-wrap stage-item" style={{ padding: 0 }}>
      <img src={image} alt={education.name} height={520} width={'100%'} style={{ objectFit: 'cover', objectPosition: 'top' }} />
      <div className="styleguide-section-wrap">
        <h1>{education.name}</h1>
        <div className="paragraph-70" dangerouslySetInnerHTML={{ __html: education.desccription }}></div>
      </div>
    </div>
  ) : (
    <div role="listitem" className=" stage-item-sm">
      <HashLink to={'/education#' + education.id}>
        <div id="w-node-_049bf1f7-3a3a-4d9b-d5e9-5c4146cdc1d0-56830c13" style={{ backgroundImage: `url(${image})` }} className="product2-content-wrap education-item-hover-image">
          <div className="product2-content-card-wrap">
            <div className="product2-name-wrap size3-text">{education.name}</div>
            <div className="button-text w-inline-block">
              <div className="button-label">Mehr erfahren</div>
              <img src="https://assets.website-files.com/5d9d08afa8a650dd43830c03/5d9d08afa8a6500021830c44_arrow-dark%402x.svg" alt="" className="button-arrow" />
            </div>
          </div>
        </div>
      </HashLink>
    </div>
  );
};

export default EducationItem;
